import React from "react";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import {Section, Text, MainTitle, Title} from "../components/Core";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import FreshStreamWidget from "../sections/careers/FreshStreamWidget";
import OutlinedLightButton from "../components/Core/OutlinedLightButton";
import employees from "../assets/image/png/employees.png";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { navigate } from 'gatsby';

import allForOne from "../assets/image/png/AllForOne.png";
import dare from "../assets/image/png/Dare.png";
import haveFun from "../assets/image/png/HaveFun.png";

import annualLeave from "../assets/image/png/AnnualLeave.png";
import broadband from "../assets/image/png/Broadband.png";
import shares from "../assets/image/png/Shares.png";
import discounts from "../assets/image/png/Discounts.png";
import bonus from "../assets/image/png/Bonus.png";
import remote from "../assets/image/png/Remote.png";

import inside1 from "../assets/image/jpeg/inside-netomnia-photo-1.jpg";
import inside2 from "../assets/image/jpeg/inside-netomnia-photo-2.jpg";
import inside3 from "../assets/image/jpeg/inside-netomnia-photo-3.jpg";

import zoltan from "../assets/image/jpeg/zoltan.jpg";
import aaron from "../assets/image/jpeg/aaron.jpg";

const Careers = () => {

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        768: { items: 2 },
        1024: { items: 3 },
    };

    const responsiveInside = {
        0: { items: 1 }
    };

    const itemsHowWeWork = [
        <>
            <div className="center-items pb-3">
                <img src={allForOne} className="icon" alt="Netomnia" />
                <h3 className="my-4">All<br />for One</h3>
                <hr />
                <p className="px-3">Working towards our vision, every team member’s daily contribution drives us forward. As we continuously succeed and reach our goals, we celebrate our victories as a team, recognising everybody’s involvement and achievement.</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={dare} className="icon" alt="Netomnia" />
                <h3 className="my-4">Dare<br />to be You</h3>
                <hr />
                <p className="px-3">Each and every one of our colleagues is what makes our team great. Your individual and personal insights can spark organisation-wide change. We continually look for better ways of working, so challenge the status quo, share your unique perspective, ask your questions and remain curious.</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={haveFun} className="icon" alt="Netomnia" />
                <h3 className="my-4">Have Fun &<br />Get Sh*t Done</h3>
                <hr />
                <p className="px-5">Laugh with your team, love what you do and ensure together, that we keep moving towards our long-term goals whilst having some fun on the way.</p>
            </div>
        </>
    ];

    const itemsTheExtras = [
        <>
            <div className="center-items pb-3">
                <img src={annualLeave} className="icon" alt="Netomnia" />
                <h3 className="my-4">25 days<br />annual leave</h3>
                <hr />
                <p className="px-3">We offer 25 days of annual leave for you to use as you desire plus bank holidays!</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={broadband} className="icon" alt="Netomnia" />
                <h3 className="my-4">Free<br />broadband</h3>
                <hr />
                <p className="px-3">With hundreds of thousands of premises already having access to our network around the UK, if you live within one of our build areas, you’ll receive free broadband from our ISP partner You. Friends and family get a discounted package.</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={shares} className="icon" alt="Netomnia" />
                <h3 className="my-4">Equity / Company<br />shares</h3>
                <hr />
                <p className="px-3">Your work will directly contribute to our success, so you should reap the rewards. You’ll receive shares in Netomnia, just like the rest of the team.</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={discounts} className="icon" alt="Netomnia" />
                <h3 className="my-4">Gym and retail<br />discounts</h3>
                <hr />
                <p className="px-3">For gym lovers and shopaholics, you can take advantage of discounted prices at popular retail/gym brands.</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={bonus} className="icon" alt="Netomnia" />
                <h3 className="my-4">Discretionary annual<br />bonus (up to 30%!)</h3>
                <hr />
                <p className="px-3">Receive a bonus of up to 30% your salary if we hit our targets and smash our goals. The best part? This opportunity happens every year!</p>
            </div>
        </>,
        <>
            <div className="center-items pb-3">
                <img src={remote} className="icon" alt="Netomnia" />
                <h3 className="my-4">Remote<br />working</h3>
                <hr />
                <p className="px-3">The best people don’t always live on our doorstep, that’s why we’re a completely remote workforce. Our HQ is based in Tewkesbury which may require occasional travel should your team need a central place to collaborate.</p>
            </div>
        </>
    ];

    const itemsTheExtras2 = [
        <div className="extras">
            <div className="center-items pb-3">
                <img src={annualLeave} className="icon" alt="Netomnia" />
                <h3 className="my-4">25 days<br />annual leave</h3>
                <hr />
                <p className="px-3">We offer 25 days of annual leave for you to use as you desire plus bank holidays!</p>
            </div>
            <div className="center-items pb-3">
                <img src={broadband} className="icon" alt="Netomnia" />
                <h3 className="my-4">Free<br />broadband</h3>
                <hr />
                <p className="px-3">With hundreds of thousands of premises already having access to our network around the UK, if you live within one of our build areas, you’ll receive free broadband from our ISP partner You. Friends and family get a discounted package.</p>
            </div>
            <div className="center-items pb-3">
                <img src={shares} className="icon" alt="Netomnia" />
                <h3 className="my-4">Equity / Company<br />shares</h3>
                <hr />
                <p className="px-3">Your work will directly contribute to our success, so you should reap the rewards. You’ll receive shares in Netomnia, just like the rest of the team.</p>
            </div>
        </div>,
        <div className="extras">
            <div className="center-items pb-3">
                <img src={discounts} className="icon" alt="Netomnia" />
                <h3 className="my-4">Gym and retail<br />discounts</h3>
                <hr />
                <p className="px-3">For gym lovers and shopaholics, you can take advantage of discounted prices at popular retail/gym brands.</p>
            </div>
            <div className="center-items pb-3">
                <img src={bonus} className="icon" alt="Netomnia" />
                <h3 className="my-4">Discretionary annual<br />bonus (up to 30%!)</h3>
                <hr />
                <p className="px-3">Receive a bonus of up to 30% your salary if we hit our targets and smash our goals. The best part? This opportunity happens every year!</p>
            </div>
            <div className="center-items pb-3">
                <img src={remote} className="icon" alt="Netomnia" />
                <h3 className="my-4">Remote<br />working</h3>
                <hr />
                <p className="px-3">The best people don’t always live on our doorstep, that’s why we’re a completely remote workforce. Our HQ is based in Tewkesbury which may require occasional travel should your team need a central place to collaborate.</p>
            </div>
        </div>
    ];

    const itemsInside = [
        <img src={inside1} className="slide" alt="Netomnia" />,
        <img src={inside2} className="slide" alt="Netomnia" />,
        <img src={inside3} className="slide" alt="Netomnia" />
    ];

    const data = useStaticQuery(graphql`
        query strapiCareersPageQuery {
            strapiCareersPage {
                title
                subtitle_1
                text_1
                subtitle_2
                text_2
                no_available_jobs_text
                application_text
            }
            strapiPageHeadings(slug: {eq: "careers"}) {
                h1
            }
        }
    `);

    const pageData = getProperty(data, 'strapiCareersPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    return (
        <div className="no-padding-wrapper careers">
            <Seo page="careers"/>
            <PageWrapper footerDark>
                <Section className="careers-feature pb-5">
                    <Container>
                        <Row>
                            <Col sm="8" lg="5" className="justify-content-center mb-5 pb-5">
                                <MainTitle variant="hero">Take up the challenge</MainTitle>
                                <Text
                                    as="h4"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    Ready to unleash your potential?
                                </Text>
                                <OutlinedLightButton borderRadius={100} className="mx-auto" onClick={() => navigate('/careers/#jobs')}>
                                    View Roles
                                </OutlinedLightButton>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="careers-we-are">
                    <Container>
                        <Row>
                            <Col sm="12" md="12" lg="8" >
                                <Title
                                    as="h2"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    We’re Netomnia
                                </Title>
                                <Text>
                                Founded in 2019, we’re the fastest-growing broadband network operator in the UK, providing homes
and businesses with exclusively full-fibre connections.
                                </Text>
                            </Col>
                            <Col sm="12" md="12" lg="4" className="d-flex align-items-end justify-content-center pt-5">
                                <img src={employees} alt="Netomnia Careers" />
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="careers-work pb-5">
                    <Container>
                        <Row>
                            <Col lg="12" className="justify-content-center text-center">
                                <Title
                                    as="h2"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    How we work
                                </Title>
                                <Text>
                                    Our principles are the fuel to our fire and the driving force behind how we work:
                                </Text>
                                <iframe className="ytPlayer mt-5" frameborder="0" allowfullscreen="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="YouFibre" src="https://www.youtube.com/embed/ZyyQIxF2mc0?autoplay=0&amp;playsinline=1&amp;start=1&amp;enablejsapi=1&amp;controls=0&amp;fs=0&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;disablekb=1&amp;playlist=ZyyQIxF2mc0&amp;widgetid=1"></iframe>
                            </Col>
                            <Col lg="12" className="bottom-background justify-content-center text-center">
                                <AliceCarousel
                                    mouseTracking
                                    responsive={responsive}
                                    disableButtonsControls={true}
                                    infinite={true}
                                    items={itemsHowWeWork}
                                    controlsStrategy="alternate" />
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <div className="careers-testimonial">
                    <Container className="zoltanBackground">
                        <Row>
                            <Col sm="12" md="12" lg="6" className="pt-5 pb-5 d-flex align-items-start justify-content-center flex-column">
                                <Text>"We work within an ever-evolving industry."</Text>
                                <Text>"We've built a team of the most knowledgeable people in the sector and we know that having people who share our vision as part of our team will only make us stronger and help us achieve our goals faster."</Text>
                                <Text>"If you thrive when taking up a challenge, then I highly encourage you to join us here at Netomnia, the fastest-growing network operator in the country."</Text>
                                <Text>Zoltan Kovacs,<br />Managing Director</Text>
                            </Col>
                            <Col sm="12" md="12" lg="0" className="d-flex align-items-end justify-content-center d-lg-none d-md-flex">
                                <img src={zoltan} alt="Netomnia Careers" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Section className="careers-deal pb-5">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5 justify-content-center text-center">
                                <Title
                                    as="h2"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    The extras
                                </Title>
                                <Text>
                                    We understand that work isn’t everything and that our lives are complex and rich with family, friends, hobbies and interests.
                                </Text>
                                <Text>
                                    That’s why we offer an excellent benefits package which we are constantly improving and building on to help our team be at their best.
                                </Text>
                            </Col>
                            <Col lg="12" className="mt-5 justify-content-center text-center d-none d-lg-block">
                                <AliceCarousel
                                    mouseTracking
                                    responsive={responsiveInside}
                                    disableButtonsControls={true}
                                    infinite={true}
                                    items={itemsTheExtras2}
                                    controlsStrategy="alternate" />
                            </Col>
                            <Col lg="12" className="mt-5 justify-content-center text-center d-block d-lg-none">
                                <AliceCarousel
                                    mouseTracking
                                    responsive={responsive}
                                    disableButtonsControls={true}
                                    infinite={true}
                                    items={itemsTheExtras}
                                    controlsStrategy="alternate" />
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="careers-inside pt-5 pb-0 justify-content-center text-center">
                    <Title
                        as="h2"
                        color="heading"
                        fontSize="1.7em"
                        fontWeight={600}
                        letterSpacing={-0.75}
                        mb={0}
                        className="mb-5 mt-0"
                    >
                        Inside Netomnia
                    </Title>
                    <AliceCarousel
                        responsive={responsiveInside}
                        disableButtonsControls={false}
                        infinite={true}
                        items={itemsInside}
                        autoPlay={false}
                        autoPlayInterval="3000"
                        disableDotsControls={true}
                        controlsStrategy="alternate" />
                </Section>
                <div className="careers-testimonial">
                    <Container>
                        <Row>
                            <Col sm="12" md="12" lg="6" className="pt-5 pb-5 d-flex align-items-start justify-content-center flex-column">
                                <Text>"I am proud to be a part of the team with other like-minded people that are hungry to succeed. There’s a strong culture of empowerment where colleagues are encouraged to take ownership and make decisions that benefit us as a whole. There are ample opportunities to grow and progress your career with Netomnia."</Text>
                                <Text>Aaron Stokes,<br />Project Manager</Text>
                            </Col>
                            <Col sm="12" md="12" lg="6" className="d-flex align-items-end justify-content-center">
                                <img src={aaron} alt="Netomnia Careers" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Section id="jobs" className="careers-offers">
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <Title
                                    as="h2"
                                    color="heading"
                                    fontSize="1.7em"
                                    fontWeight={600}
                                    letterSpacing={-0.75}
                                    mb={0}
                                    className="mb-5 mt-0"
                                >
                                    Current opportunities
                                </Title>
                                <FreshStreamWidget />
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </div>
    )
}

export default Careers;
